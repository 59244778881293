<template>
  <message-card v-if="!division.showTeams && !admin">
    <h2 class="ma-12 text-center">
      <v-avatar color="red">
        <v-icon color="white">fas fa-eye-slash</v-icon>
      </v-avatar>
      <br class="hidden-md-and-up">
      The tournament host has not published the coaches yet.
    </h2>
  </message-card>

  <v-data-table
    v-else
    :items="coaches"
    :headers="headers"
    :loading="division.loading || loading"
    :options.sync="page"
    :search="search"
    class="elevation-1"
    hide-default-footer
    dense
  >
    <template v-slot:progress>
      <v-progress-linear color="color3" indeterminate></v-progress-linear>
    </template>
    <template v-slot:[`item.name`]="{ item }">
      <div v-if="admin" class="color3--text clickable" @click.stop="teamClick(item.id)">{{item.name}}</div>
      <div v-else>{{item.name}}</div>
    </template>
    <template v-slot:[`item.teams`]="{ item }">
      <table class="nested">
        <tr v-for="(team) in item.teams" :key="team.id">
          <td><div class="color3--text clickable" @click.stop="teamClick(team.id)">{{team.name}}</div></td>
        </tr>
      </table>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: ['division', 'search', 'admin'],
  data () {
    return {
      page: { itemsPerPage: -1 },
      loading: false
    }
  },
  computed: {
    ...mapGetters(['tournament', 'getDivisionJProp', 'getDivisionJProps', 'getTeam']),
    isCoaches () {
      return this.division && this.division.isCoaches
    },
    coachProps () {
      return this.division && this.division.coachProps
    },
    coaches () {
      return this.division.activeTeams.map(m => {
        const teams = m.registrationData && m.registrationData.extras && m.registrationData.extras.find(f => f.name === 'Teams')
        const teamList = teams ? JSON.parse(teams.value) : []
        return {
          id: m.id,
          name: m.name,
          club: m.club,
          teams: teamList.map(m => this.getTeam(m.id))
        }
      })
    },
    headers () {
      const heads = [
        { text: 'Name', value: 'name', align: 'left', sortable: true }
      ]
      if (this.coachProps && this.coachProps.team) {
        heads.push({ text: 'Club', value: 'club', sortable: false })
        heads.push({ text: 'Team(s)', value: 'teams', sortable: false })
      }
      return heads
    }
  },
  methods: {
    teamClick (id) {
      this.$router.push({ name: 'division-team', params: { tournamentId: this.tournament.id, teamId: id } })
    },
    loadData () {
      this.loading = true
      this.$VBL.tournament.teams.refresh(this.division.id)
        .then(r => {
          this.division.update({ teams: r.data })
        })
        .catch(e => console.log(e))
        .finally(() => { this.loading = false })
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>

<style scoped>
  .noWrap {
    white-space: nowrap;
  }
  .deleted {
    text-decoration: line-through
  }
  .pt td {
    border: none !important;
    height: auto !important;
  }
  .mini >>> table tbody td,
  .mini >>> table thead th {
    padding: 0 !important;
    padding-left: 12px !important;
  }
  .nested th, .nested td {
    padding: 0 !important;
    height: auto !important;
    border-bottom: none !important;
  }
</style>
